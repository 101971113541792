<template>
  <MockExample v-if="isDev" />
  <section
    v-else
    class="warning">
    <code>import { DefaultSearchBox, ActiveSearchBox, FocusedSearchBox } from '@shein-aidc/bs-search-box-mobile-vue2'</code>
  </section>
</template>

<script name="SearchBox" setup lang="ts">
import MockExample from './mocks/MockExample.vue'


const isDev = (import.meta as any).env.DEV

if (isDev) {
  console.log('SearchBox is in development mode')
}

</script>

<style scoped>
.warning {
  color: red;
}
</style>
