<template>
  <div
    :class="[
      'sideslip-image-dynamic',
      'sideslip-image-slider',
      isNeedCardBg ? 'has-padding' : '',
      intervalSpacing ? 'has-item-space' : '',
    ]"
    :style="[cardImgRadiusStyle()]"
  >
    <template v-for="(item, i) in itemList">
      <BaseImg
        v-if="item.image"
        :key="i"
        v-tap="{
          id: 'click_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        v-expose="{
          id: 'expose_block_main.comp_top-banner',
          data: { item, index: i, compIndex: index },
        }"
        class="sideslip-image-slider__item-three"
        :brand="brand"
        :placeholder="{
          width: item.image.width,
          height: item.image.height,
        }"
        :style="[cardImgRadiusStyle(i), { overflow: 'hidden' }]"
        :special-radius="
          intervalSpacing ? cardImgRadiusStyle(i)?.borderRadius : ''
        "
        :ratio="item.image.ratio"
        :img-src="item.image.src"
        :img-design-width="216"
        :ada="item.ada"
        :first-screen="true"
        :comp-src="'sideslip-image-dynamic'"
        @click="clickItem(item, i)"
        @base-image-mounted="handleBaseImageMounted"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'ThreePointFiveImageSlide',
}
</script>

<script setup>
import { defineProps, defineEmits, computed, inject } from 'vue'
import BaseImg from '../../base/BaseImg.vue'
import { isUnDef } from '@shein/common-function'
import { commonProps } from '../../common/common-props.js'
import { useLink } from '@shein-aidc/bs-sdk-ccc-link'
const { vTap, vExpose } = inject('analysisInstance')

const props = defineProps({
  ...commonProps,
})
const emits = defineEmits(['base-image-mounted'])

const { cccLink, jumpLink } = useLink({
  propData: props.propData,
  sceneData: props.sceneData,
})

const metaData = computed(() => props.propData?.props?.metaData || {})
const extendData = computed(() => props.sceneData?.extendData || {})
const intervalSpacing = computed(() => metaData.value?.intervalSpacing || false)
const titleStyle = computed(() => {
  return {
    titleColor: metaData.value?.titleColor || '#222222',
    titlePosition: metaData.value?.titlePosition || 'left',
    titleText: metaData.value?.titleText || '',
  }
})

const itemList = computed(() => props?.propData?.props?.items || [])
const cardImgRadiusStyleMap = computed(() => {
  const radius = cardImgRadius()
  const start = `${radius} 0 0 ${radius}`
  const end = `0 ${radius} ${radius} 0`
  return {
    start: {
      borderRadius: start,
    },
    end: {
      borderRadius: end,
    },
    all: {
      borderRadius: radius,
    },
  }
})
const isNeedCardBg = computed(() => {
  return Boolean(titleStyle.value?.titleText)
})

const cardImgRadiusStyle = i => {
  let styleResult = {}
  const { start, all } = cardImgRadiusStyleMap.value
  if (intervalSpacing.value) {
    styleResult = isUnDef(i) ? {} : all
  } else {
    styleResult = isUnDef(i) ? (isNeedCardBg.value ? all : start) : {}
  }
  return styleResult
}

const handleBaseImageMounted = () => {
  emits('base-image-mounted')
}

const cardImgRadius = () => {
  const { cardRadius = 0 } = extendData.value || {}
  return `${isNeedCardBg.value ? 2 : Number(cardRadius)}px`
}

const clickItem = (item, i) => {
  const url = cccLink.getFullLink({
    item,
    cateLinks: props.cateLinks,
    compIndex: props.index,
    index: i,
  })

  jumpLink({ url, hrefType: item.hrefType })
}
</script>
<style lang="less" scoped>
@vw: 375/100vw;
.sideslip-image-slider {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  line-height: 18px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  &__item-three {
    position: relative;
    display: inline-block;
    width: 104 / @vw;
    vertical-align: top;
  }

  .margin-right:not(:last-child) {
    margin-right: 8 / @vw;
  }
}

.has-item-space {
  padding: 0;
  .sideslip-image-slider__item-three {
    margin-right: 4 / @vw;
    width: 96 / @vw;
    &:last-child {
      margin-right: 0;
    }
  }

  .sideslip-image-swiper {
    margin-bottom: -0.2133rem;
  }
  .sideslip-image-swiper__item-three {
    margin-bottom: 0.2133rem;
  }

  .last-line {
    margin-bottom: 0;
  }
}
.has-padding {
  padding: 0 3.2vw;
}
</style>
