const compTypes = {
  IMAGE_COMPONENT: 'StaticImageCardController', // 静态图组件（单图、三图）
  // 横滑动3.5图
  MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC: 'SideslipImageDynamic',
  BANNER_COUNTDOWN: 'Countdown', // 倒计时
}

export const compMapping = ({ styleType = '', componentKey = '' }) => {
  return compTypes[styleType] || compTypes[componentKey] || ''
}
