import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_SearchBox } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getSearchBoxFsDataRunner(dataSource?: DS_SearchBox.DataSource) {
  return getFsDataRunner(dataSource, useAppConfigs())
}

export { default, default as BSearchBox } from './search-box.vue'

export { default as DefaultSearchBox } from './components/DefaultSearchBox'
export { default as ActiveSearchBox } from './components/ActiveSearchBox'
export { default as FocusedSearchBox } from './components/FocusedSearchBox'