<template>
  <section>
    <div style="width: 248px; margin: auto;
      --box-bg-color: #F5F5F5;
      --search-box-border-color: #E5E5E5;
      --sui-icon-search-color: #000;
      --sui-icon-search-size: 20px"
    >
      <DefaultSearchBox
        show-search-icon
        box-type="square"
      >
      </DefaultSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto;
      --search-box-bg-color: #F5F5F5;
      --search-box-border-color: #E5E5E5;
      --sui-icon-search-color: #000;
      --sui-icon-search-size: 20px;
      --search-box-search-button-color: transparent;
      --search-box-search-icon-color: #000;
      --search-box-search-button-padding-left: 6px;
      --search-box-search-button-padding-right: 6px;
      --search-box-search-icon-size: 20px;">

      <DefaultSearchBox
        show-search-icon
        box-type="round"
        :search-button-type="'text'"
        :show-search-button="true"
        @searchIconClick="handleSearchIconClick"
        @searchTermClick="handleSearchIconClick"
        @searchButtonClick="handleSearchButtonClick"
      >
        <template #search-icon>
          <span>label</span>
        </template>
        <template #search-button-icon>
          <span>icon</span>
        </template>
      </DefaultSearchBox>

    </div>
    <hr />
    <div style="width: 248px; margin: auto">
      <FocusedSearchBox
        ref="inputRef"
        v-model="text2"
        boxType="none"
        placeholder="Women Sweatshirts"
        :show-search-button="false"
        search-button-type="square"
      ></FocusedSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto">
      <FocusedSearchBox
        v-model="text2"
        boxType="square"
        placeholder="Women Sweatshirts"
        :show-search-button="true"
        search-button-type="square"
        :show-camera-icon="true"
      ></FocusedSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto">
      <FocusedSearchBox
        v-model="text2"
        boxType="square"
        placeholder="Women Sweatshirts"
        showCameraIcon
        search-button-type="text"
      >
        <!-- <template #searchButton>
          search go
        </template> -->
    </FocusedSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto">
      <FocusedSearchBox v-model="text2" boxType="round" placeholder="Women Sweatshirts"></FocusedSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto">
      <ActiveSearchBox
        :style="{ '--active-search-default-text-color': 'rgb(34, 34, 34)' }"
        boxType="round"
        :value.sync="text"
      ></ActiveSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto">
      <ActiveSearchBox
        :isTagMode="true"
        :tag-list="[
          { filterId: 1, filterWord: 'Women Ankle Boots' },
          { filterId: 2, filterWord: 'Ankle Boots' },
          { filterId: 3, filterWord: 'Shoes' },
        ]"
      ></ActiveSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto; outline: 1px solid blueviolet;">
      <ActiveSearchBox
        :box-type="'none'"
        :showSearchButton="true"
        :searchButtonType="'round'"
        :value.sync="text"
        :isTagMode="false"
        :tag-list="[
          { filterId: 1, filterWord: 'Women Ankle Boots' },
          { filterId: 2, filterWord: 'Ankle Boots' },
          { filterId: 3, filterWord: 'Shoes' },
        ]"
      ></ActiveSearchBox>
    </div>
    <hr />
    <div style="width: 248px; margin: auto; --box-bg-color: pink; outline: 1px solid khaki">
      <DefaultSearchBox show-search-icon showCameraIcon boxType="round" fixedFirstTerm @resolveSearchTerms="handleTest"></DefaultSearchBox>
    </div>
    <hr />

    <div style="width: 248px; margin: auto">
      <DefaultSearchBox show-search-button showCameraIcon></DefaultSearchBox>
    </div>
    <hr />

    <div style="width: 248px; margin: auto">
      <DefaultSearchBox show-search-icon box-type="square" showCameraIcon></DefaultSearchBox>
    </div>
    <hr />

    <div style="width: 248px; margin: auto; outline: 1px solid red">
      <button @click="handleClickParams">changed params</button>
      <DefaultSearchBox
        :autoFetch="true"
        box-type="square"
        show-search-button
        search-button-type="square"
        showCameraIcon
        :showTermIcon="false"
        :hotTermIndex="2"
        :fetch-search-terms-params="params"
        @searchTermClick="handleSearchTermClick"
      ></DefaultSearchBox>
    </div>
    <hr />

    <div style="width: 248px; margin: auto">
      <DefaultSearchBox :slide-items="mockKeywords" show-search-icon box-type="round" showCameraIcon
        @searchTermTransitionEnd="handleSearchTermTransitionEnd"
      ></DefaultSearchBox>
    </div>
    <hr />

    <div style="width: 248px; margin: auto; outline: 2px solid blueviolet;">
      <DefaultSearchBox
        box-type="round"
        search-button-type="round"
        show-search-button
        showCameraIcon
        ref="defaultSearchBoxRef"
      ></DefaultSearchBox>
    </div>
    <hr />
    <div>
      <SearchIcon />
      <CameraIcon />
      <ClearIcon />
    </div>
    <hr />
    <div style="width: 48px">
      <SearchButton type="square" @click.native="handleClick" />
    </div>
    <hr />
    <div style="width: 48px">
      <SearchButton type="round" />
    </div>
    <hr />
    <div style="background-color: rgb(246, 246, 246); height: 39px; display: grid; place-content: center">
      <TagButton>Women Flat Sandals</TagButton>
    </div>
    <hr />
    <div style="background-color: rgb(246, 246, 246); height: 39px; display: grid; place-content: center">
      <TagButton>Shoes</TagButton>
    </div>
    <hr />
    <Box type="none"></Box>
    <hr />
    <Box type="round"></Box>
    <hr />
    <Box type="square"></Box>
    <hr />
  </section>
</template>

<script name="MockExample" setup lang="ts">
// 埋点源：
import { useAnalysis } from '../../../../search-box/common/analysisSource'
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { AS_SearchBox, DS_SearchBox } from '../../../types'
import CameraIcon from '../components/CameraIcon'
import SearchIcon from '../components/SearchIcon'
import SearchButton from '../components/SearchButton'
import Box from '../components/Box'
import ClearIcon from '../components/ClearIcon'
import DefaultSearchBox from '../components/DefaultSearchBox'
import { onMounted, ref, type Ref } from 'vue'
import { keywords } from './keywords'
import TagButton from '../components/TagButton'
import { getSource } from '../../../../search-box/common/dataSource'
import ActiveSearchBox from '../components/ActiveSearchBox'
import FocusedSearchBox from '../components/FocusedSearchBox'

const inputRef = ref<InstanceType<typeof FocusedSearchBox> | null>(null)

  const handleSearchTermTransitionEnd = () => {
    console.log('searchTermTransitionEnd')
  }

onMounted(() => {
  inputRef.value?.focusedInput()
})

const appConfigs = useAppConfigs()
const props = defineProps<{
  fsData?: DS_SearchBox.FS_DATA
  dataSource?: DS_SearchBox.DataSource
  analysisSource?: AS_SearchBox.AnalysisSource
}>()
const apis = getSource(props.dataSource, appConfigs)

const mockKeywords = ref(keywords)

const x = ref({ word_type: 2, scene: 'home' })
const text = ref('hello word')
const text2 = ref('x')

const handleSearchTermClick = (item: any) => {
  console.log('searchTermClick', item)
}

async function getKeywords() {
  //   _ver: 1.1.8
  // _lang: en
  // goods_id:
  // word_type: 1
  // cat_id:
  // select_id:
  // list_scene:
  // scene: home
  const results = await apis.getKeywordsWithBff({
    _ver: '1.1.8',
    _lang: 'en',
    word_type: 1,
    scene: 'home',
  })
}

const handleClick = () => {
  console.log('click')
  x.value.scene = 'detail'
  x.value.word_type = 1
}

const handleSearchButtonClick = () => {
  console.log('searchButtonClick')
}

const handleSearchIconClick = (item, index) => {
  console.log('searchIconClick', item, index)
}

// getKeywords()

// 数据源：END
useAnalysis(props.analysisSource)
// 埋点源：END

// 内置路由：
// appConfigs.$router.push({ path: `/somepath`, query: { test: 123 } })
// 内置路由：END

const params = ref({ word_type: 2, scene: 'home' })

const handleClickParams = () => {
  params.value = { word_type: 2, scene: 'home' }
}

const handleTest = (val: any, val2: any) => {
  console.log(`===========>`, val, val2)
}

// const defaultSearchBoxRef = ref<InstanceType<typeof DefaultSearchBox> | null>(null)

// onMounted(() => {
//   console.log('defaultSearchBoxRef', defaultSearchBoxRef.value.hackUpdateSwiper())
// })
</script>
