<template>
  <div class="base-img" v-bind="props" :data-img-src="getBaseCutImg()">
    <a
      href="javascript:;"
      :style="{ paddingBottom: customRatio || getImgPlaceholder() }"
      class="base-img__href"
      role="link"
      tabindex="0"
      :aria-label="ada"
      @click="clickImg"
    >
      <img
        v-if="firstScreen || showImmediately"
        :class="[
          'base-img__inner',
          `base-img__${fit}`,
          'fsp-element',
          compSrc ? `fsp__${compSrc}` : '',
        ]"
        :src="getBaseCutImg()"
        :style="getImmediateImageStyle()"
        :alt="alt"
        fetchpriority="high"
      />
      <img
        v-else
        :class="['base-img__inner lazyload', `base-img__${fit}`, compSrc ? `fsp__${compSrc}` : '']"
        :style="{
          objectPosition: position,
          ...raidusStyle,
        }"
        :data-src="imgSrc"
        :src="emptyImg || EmptyNewImg"
        :data-design-width="imgDesignWidth"
        :alt="alt"
        :data-exp="imgDataExpStr"
      />
    </a>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

defineComponent({
  name: 'BaseImg',
})
</script>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { imgExpandData2Str } from '@shein-aidc/basis-resource'

const EmptyNewImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP89B8AAukB8/71MdcAAAAASUVORK5CYII='

const props = defineProps({
  imgSrc: {
    type: String,
    default: '',
  },
  fit: {
    type: String,
    default: 'initial',
  },
  position: {
    type: String,
    default: 'initial',
  },
  placeholder: {
    type: Object,
    default: () => ({
      width: 500,
      height: 500,
      query: '',
    }),
  },
  ratio: {
    type: [Number, String],
    default: 0,
  },
  ada: {
    type: String,
    default: 'Photo',
  },
  alt: {
    type: String,
    default: '',
  },
  brand: {
    type: String,
    default: 'shein',
  },
  imgDesignWidth: {
    type: [Number, String],
    default: 0,
  },
  imgDataExp: {
    type: Object,
    default: () => ({}),
  },
  firstScreen: {
    type: Boolean,
    default: false,
  },
  customRatio: {
    type: String,
    default: '',
  },
  emptyImg: {
    type: String,
    default: '',
  },
  specialRadius: {
    type: String,
    default: '',
  },
  compSrc: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['base-image-mounted', 'click'])

const showImmediately = inject('showImmediately', false)
const cutImg = inject('cutImg', url => url)

const imgDataExpStr = imgExpandData2Str(props.imgDataExp)
const raidusStyle = ref(props.specialRadius ? { borderRadius: props.specialRadius } : {})

onMounted(() => {
  emits('base-image-mounted')
})

const getImmediateImageStyle = () => {
  return showImmediately
    ? {
        width: '100%',
        height: '100%',
        'vertical-align': 'top',
        position: 'absolute',
        left: 0,
        objectPosition: props.position,
        ...raidusStyle.value,
      }
    : {
        objectPosition: props.position,
        ...raidusStyle.value,
      }
}

const getBaseCutImg = () => {
  if (!cutImg) {
    return props.imgSrc.replace(/^https?\:/, '')
  }

  return cutImg(props.imgSrc, props.imgDesignWidth, props.imgDataExp)
}

const clickImg = event => {
  emits('click', event)
}

const getImgPlaceholder = () => {
  if (props.ratio) {
    return (100 / props.ratio).toFixed(2) + '%'
  } else {
    const { height, width } = props.placeholder
    return ((height / width) * 100).toFixed(2) + '%'
  }
}
</script>

<style lang="less">
.base-img {
  width: 100%;
  position: relative;
  overflow: hidden;

  &__href {
    width: 100%;
    display: block;
  }

  &__inner {
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: absolute;
    left: 0;
  }
  &__contain {
    object-fit: contain;
  }
  &__cover {
    object-fit: cover;
  }
  &__scale-down {
    object-fit: scale-down;
  }
  &__initial {
    object-fit: initial;
  }
  &__inherit {
    object-fit: inherit;
  }
  img[src=''],
  img:not([src]) {
    opacity: 0;
  }
}
</style>
