<!-- eslint-disable vue/require-explicit-emits -->
<template>
  <div
    :style="itemStyle"
    :class="{ 's-swiper-item': vertical }"
    @click="$emit('click', $event, index)"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SSwiperItem',
  inject: {
    spaceBetween: {
      from: 'spaceBetween',
      default: 0,
    },
    direction: {
      from: 'direction',
      default: 'ltr',
    },
    vertical: {
      from: 'vertical',
      default: false,
    },
  },
  props: {
    index: {
      // 索引
      type: Number,
      default: -1,
    },
  },
  computed: {
    itemStyle() {
      const style = {}
      if (this.vertical) {
        return style
      }
      if (this.index === 0 || !this.spaceBetween) return style

      style[this.getDirectionLabel(`marginLeft`)] = `${this.spaceBetween}px`

      return style
    },
  },
  methods: {
    getDirectionLabel(property) {
      if (this.direction === 'ltr') {
        return property
      }

      return {
        marginLeft: 'marginRight',
      }[property]
    },
  },
}
</script>

<style lang="less" scoped>
.s-swiper-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}
</style>
