const keywords = [
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Summer Work Dress Work Dress',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'model-hw_us_v06',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: {
  //     icon: null,
  //     labelType: null,
  //     labelFront: null,
  //   },
  // },
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Midi Dresses Work Dress',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'model-hw_us_v06',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: null,
  // },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Sunday Dress',
    imgSrc: null,
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'model-hw_us_v06',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: null,
      labelType: 'like',
      labelFront: '100+ likes',
    },
  },
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Empire Waist Dress',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'model-hw_us_v06',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: null,
  // },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Women Blouses Work Dress Work Dress',
    imgSrc: null,
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'latest',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
      labelType: 'hot',
      labelFront: '',
    },
  },
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Casual Dresses Work Dress',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'model-hw_us_v06',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: {
  //     icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
  //     labelType: 'hot',
  //     labelFront: '',
  //   },
  // },
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Office Wear Women',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'latest',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: {
  //     icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
  //     labelType: 'hot',
  //     labelFront: '',
  //   },
  // },
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Work Clothes Professional',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'latest',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: {
  //     icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
  //     labelType: 'hot',
  //     labelFront: '',
  //   },
  // },
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Shirts Work Dress Work Dress Work Dress Work Dress Work Dress',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'insitehot',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: {
  //     icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
  //     labelType: 'hot',
  //     labelFront: '',
  //   },
  // },
  // {
  //   brand: null,
  //   crowdId: null,
  //   siteLang: null,
  //   terminal: null,
  //   weight: null,
  //   word: 'Lingerie',
  //   imgSrc: null,
  //   pageId: null,
  //   pageType: null,
  //   pageUrl: null,
  //   sort: null,
  //   type: 'insitehot',
  //   from: 'search',
  //   mall_code_list: null,
  //   route_url: null,
  //   cateIds: null,
  //   categoryChannel: null,
  //   wordLabel: {
  //     icon: 'https://img.shein.com/images3/2023/10/27/f5/1698394226527e3792513f865e0b2d6124c9e7a646.png',
  //     labelType: 'trend',
  //     labelFront: '',
  //   },
  // },
]

export { keywords }
