/**
 * 判断对象是否冻结
 * @param {Object} obj
 * @returns
 */
export function handleObjIsFrozen(obj) {
  // 若原始的obj已被冻结 深拷贝一份
  return Object.isFrozen(obj) ? JSON.parse(JSON.stringify(obj)) : obj
}

export function getServerTime() {
  try {
    return Date.now()
  } catch (error) {
    return null
  }
}

import { Cut } from '@shein-aidc/basis-resource'

let innerCut = null

/** 图片转化及裁切 */
function transfromAndCutImg({
  deviceData,
  isSupportWebp,
  isSupprotCut,
  imgUrl,
  designWidth,
  sceneMap,
  exp,
}) {
  const { width } = deviceData2Obj(deviceData)
  // 服务端裁切
  if (typeof window === 'undefined') {
    return new Cut({
      width,
      isMobile: true,
      isSupportWebp,
      isSupprotCut,
      sceneMap,
    }).transfromImg({
      imgUrl,
      width: designWidth,
      exp,
    })
  }
  // 客户端裁切
  if (!innerCut) {
    innerCut = new Cut({
      width,
      isMobile: true,
      isSupportWebp,
      isSupprotCut,
      sceneMap,
    })
  }

  return innerCut.transfromImg({
    imgUrl,
    width: designWidth,
    exp,
  })
}

function deviceData2Obj(deviceDataStr) {
  if (!deviceDataStr) {
    return {}
  }

  const obj = {}
  decodeURIComponent(deviceDataStr)
    .split('&')
    .forEach(item => {
      const [key, value] = item.split('=')
      if (key && value) {
        obj[key] = value
      }
    })

  const width = Number.isNaN(Number(obj.width)) ? undefined : Number(obj.width)
  const isIOS = obj.ios === '1'

  return {
    width,
    isIOS,
  }
}

export { transfromAndCutImg }
