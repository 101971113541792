<script lang="tsx">
import { defineComponent, onMounted, ref, onUnmounted } from 'vue'
import { getSource } from '../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useCommon, useMobileCommon, useReportCommon } from '../../hooks/common'

export default defineComponent({
  name: 'BWishListEntry',
  props: {
    ariaLabel: String,
    redPointOn: {
      type: Boolean,
      default: true,
    },
    routeQuery: Object,
    analysisSource: Object,
  },
  setup(props, { expose }) {
    // 全局配置
    const appConfigs = useAppConfigs()
    const apis = getSource({}, appConfigs) // 数据源
    const {
      redpoint,
      ariaLabel,
      showRedpoint,
      updateRedPointStatus,
    } = useCommon({ appConfigs, props, apis })

    const {
      resetExpose,
      exposeReport,
      clickReport,
    } = useReportCommon({ redpoint, props })

    const bWishListEntryRef = ref<Element | null>(null)
    const {
      goWishlist,
    } = useMobileCommon({ appConfigs, props, clickReport })

    onMounted(() => {
      if (props.redPointOn) {
        updateRedPointStatus()
      }
      if(bWishListEntryRef.value) {
        bWishListEntryRef.value.addEventListener('click', goWishlist, { passive: false })
      }
    })

    onUnmounted(() => {
      // 移除事件监听器
      if(bWishListEntryRef.value) {
        bWishListEntryRef.value.removeEventListener('click', goWishlist)
      }
    })

    expose({
      resetExpose,
      exposeReport,
      clickReport,
      updateRedPointStatus,
    })

    return () => (
      <span
        ref={bWishListEntryRef}
        class="bsc-wish-list-entry_wrap"
        tabindex="0"
        role="link"
        aria-label={ariaLabel.value}
      >
        <svg
          style="width: auto height: 1em"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.3938 4.98632C9.25839 2.96853 5.89126 3.00521 3.80009 5.09639C1.67157 7.22433 1.79597 10.2517 3.80009 12.8033C3.89976 12.9302 4.0098 13.0647 4.12892 13.2059L4.37892 13.4966L4.65088 13.803C4.69791 13.8553 4.74576 13.9081 4.79439 13.9615L5.09505 14.2877L5.41199 14.6249L5.74295 14.9709L6.08566 15.324L6.43784 15.6823L6.97891 16.2251L7.42184 16.6637L7.80117 17.0358L8.73799 17.9432L9.73299 18.8916L11.0299 20.108L12 21.0032L13.4782 19.634L15.0337 18.162L15.6098 17.6081L16.5782 16.6637L17.0211 16.2251L17.3832 15.8627L17.7393 15.5026L18.087 15.1467L18.4241 14.7969L18.7484 14.4551L19.0575 14.1231L19.3491 13.803L19.6211 13.4966L19.8711 13.2059C19.9902 13.0647 20.1002 12.9302 20.1999 12.8033C22.204 10.2517 22.3284 7.22433 20.1999 5.09639C18.0714 2.96787 14.6209 2.96787 12.493 5.09639L12 5.58882L11.507 5.09639L11.3938 4.98632ZM4.86075 6.15705C6.40346 4.61434 8.90405 4.61434 10.4462 6.1569L12 7.7089L13.5531 6.15766L13.6528 6.06125C15.2019 4.61504 17.6294 4.64717 19.1393 6.15705C20.6516 7.66897 20.6265 9.83175 19.0203 11.8768L18.8628 12.0727L18.6861 12.2843L18.4906 12.511L18.2766 12.7525L17.9223 13.1416L17.6645 13.4182L17.39 13.708C17.3429 13.7574 17.2951 13.8073 17.2466 13.8578L16.7926 14.3258L16.3041 14.8207L15.7825 15.3411L15.2662 15.8498L14.7911 16.3129C13.9912 17.0891 13.1541 17.884 12.3195 18.6639L12 18.9615L11.0797 18.1002C10.2795 17.3464 9.48556 16.5861 8.73228 15.8483L8.47296 15.5936L8.21581 15.3395L7.69483 14.8196L7.20668 14.325L6.90028 14.0102L6.47037 13.5612L6.20426 13.278L5.837 12.8786L5.50943 12.511L5.31389 12.2842L5.13717 12.0727L4.97973 11.8768C3.37349 9.83175 3.34842 7.66897 4.86075 6.15705Z"
            fill="var(--shein-common-header-icon-color, #000)"
          />
        </svg>
        {props.redPointOn && showRedpoint.value ? (
          <em
            class="bsc-wish-list-entry_point"
          ></em>
        ) : null}
      </span>
    )
  },
})
</script>
<style lang="less">
.bsc-wish-list-entry_wrap {
  height: 24px;
  position: relative;
  display: inline-block;
}
.bsc-wish-list-entry_point {
  display: 'block';
  background: #fe3b30;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  height: 0.16rem;
  width: 0.16rem;
  right: -0.08rem;
  top: -0.04rem;
}
</style>
