<template>
  <div class="focused-search-box__wrapper">
    <Box
      class="focused-search-box__box"
      :type="boxType"
    >
      <div class="focused-search-box">
        <div class="focused-search-box__content">
          <input
            ref="inputRef"
            name="keywords"
            :value="value"
            type="search"
            :placeholder="placeholder"
            autocomplete="off"
            class="focused-search-box__input"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
          />
        </div>
        <div
          v-if="value"
          class="focused-search-box__clear"
          @click="handleClear">
          <ClearIcon />
        </div>
        <div
          v-if="showCameraIcon"
          class="focused-search-box__camera"
          @click="handleCameraIconClick">
          <slot
            v-if="$slots.cameraIcon"
            name="cameraIcon"></slot>
          <CameraIcon v-else />
        </div>
        <div
          v-if="showSearchButton && searchButtonType !== 'text'"
          :class="['focused-search-box__button', searchButtonType === 'round' ? 'round-gap' : '']"
          @click="handleSearchButtonClick"
        >
          <SearchButton :type="searchButtonType" />
        </div>
      </div>
    </Box>
    <div
      v-if="showSearchButton && searchButtonType === 'text'"
      :class="['focused-search-box__text-button']"
    >
      <slot v-if="$slots.searchButton" name="searchButton"></slot>
      <SearchButton
        v-else
        :type="searchButtonType"
        @click.native="handleSearchButtonClick"
      >
        Search
      </SearchButton>
    </div>
  </div>
</template>

<script name="FocusedSearchBox" setup lang="ts">
import { ref } from 'vue'
import Box from '../Box'
import ClearIcon from '../ClearIcon'
import SearchButton from '../SearchButton'
import CameraIcon from '../CameraIcon'

const inputRef = ref<HTMLInputElement | null>(null)

type FocusedSearchBoxProps = {
  placeholder?: string
  boxType?: 'round' | 'square' | 'none'
  value: string
  showCameraIcon?: boolean
  showSearchButton?: boolean
  searchButtonType?: 'round' | 'square' | 'text'
}

withDefaults(defineProps<FocusedSearchBoxProps>(), {
  placeholder: '',
  boxType: 'round',
  showSearchButton: true,
  showCameraIcon: false,
  searchButtonType: 'round',
})

type FocusedSearchBoxEmits = {
  (e: 'cameraIconClick'): void
  (e: 'clearClick'): void
  (e: 'searchButtonClick'): void
  (e: 'focused'): void
  (e: 'blured'): void
  (e: 'update:value', value: string): void
  (e: 'input', value: string): void
}

const emit = defineEmits<FocusedSearchBoxEmits>()

const handleClear = () => {
  emit('clearClick')
  emit('update:value', '')
  emit('input', '')
}

const handleCameraIconClick = () => {
  emit('cameraIconClick')
}

const handleSearchButtonClick = () => {
  console.log('handleSearchButtonClick')
  emit('searchButtonClick')
}

const handleInput = (e: Event) => {
  const input = e.target as HTMLInputElement
  emit('update:value', input.value)
  emit('input', input.value)
}

const handleFocus = () => {
  emit('focused')
}

const handleBlur = () => {
  emit('blured')
}

const focusedInput = () => {
  inputRef.value?.focus()
}

defineExpose({
  focusedInput,
})
</script>

<style scoped>
.focused-search-box__wrapper {
  display: flex;
  align-items: stretch;
}

.focused-search-box__box {
  flex-grow: 1;
}

.focused-search-box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 10px;
}

.focused-search-box__content {
  flex: 1;
  height: 100%;
  display: flex;
  color: #000;
  /* 隐藏滚动超出部分 */
  overflow: hidden;
}

.focused-search-box__input {
  width: 100%;
  box-sizing: content-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
}

.focused-search-box__clear {
  padding-left: 5px;
  padding-right: 5px;
}

.focused-search-box__button {
  height: 100%;
}

.focused-search-box__camera {
  padding-left: 5px;
  padding-right: 10px;
}

.focused-search-box__button.round-gap {
  padding: 2px;
}
</style>
