import type { AS_PreSearchEntry } from '../../../types'

const standard: AS_PreSearchEntry.AnalysisFuncs = {
  'expose_search.comp_pre-search-entry': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_search.comp_pre-search-entry': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
