import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { Libs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_SearchBox } from '../../types'

export function getSource(dataSource: DS_SearchBox.DataSource = {}, appConfigs: Libs.AppConfigs): DS_SearchBox.APIS {
  return organizeDataSource<DS_SearchBox.APIS, DS_SearchBox.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(dataSource: DS_SearchBox.DataSource = {}, appConfigs: Libs.AppConfigs) {
  const { getKeywordsWithBff } = getSource(dataSource, appConfigs)
  return createFsDataRunnerBySource<DS_SearchBox.FS_APIS, DS_SearchBox.FS_DATA>({
    getKeywordsWithBff,
  })
}
